import BaseApi from "./BaseApi"

const PROMOTION_ADMIN_ENDPOINT = "promo-admin/"
const OFFER_DICTIONARY_ENDPOINT = "offerDictionary/"
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID

class OfferApi extends BaseApi {

  async getOfferDetails(lookupValues) {
    try {
      const endpoint = `${PROMOTION_ADMIN_ENDPOINT}lookup`
      const response =  await this.post(endpoint,lookupValues)
      return response.data
    } catch (error) {
      if (error.response.data === "A promotion with that code does not exist" ) {
        throw new Error("A promotion with that code does not exist")
      }

      throw new Error("Unable to retrieve offer details")
    }
  }

  async getOfferCampaignDetails(offerId) {
    try {
      const endpoint = `${PROMOTION_ADMIN_ENDPOINT}offer/${offerId}/details`
      return await this.get(endpoint)
    } catch (error) {
      throw new Error("Unable to retrieve offer campaign details")
    }
  }

  async getClientFaqs() {
    const clientId = process.env.REACT_APP_CLIENT_ID
    try {
      const endpoint = `${PROMOTION_ADMIN_ENDPOINT}${clientId}/faq`
      return await this.get(endpoint)
    } catch (error) {
      throw new Error("Unable to retrieve client faq")
    }
  }

  async getOfferDictionary(offerId) {
    try {
      const endpoint = `${OFFER_DICTIONARY_ENDPOINT}${offerId}`
      return await this.get(endpoint)
    } catch (error) {
      throw new Error("Unable to retrieve offer dictionary")
    }
  }

  async getChannelList() {
    try {
      const endpoint = `${PROMOTION_ADMIN_ENDPOINT}channel`
      return await this.get(endpoint)
    } catch (error) {
      throw new Error("Unable to get the channel list")
    }
  }

  async getClientReCaptchaVisible() {
    try {
      const endpoint = `${PROMOTION_ADMIN_ENDPOINT}${CLIENT_ID}/clientrecaptchavisible`
      const response =  await this.get(endpoint)
      return response.data 
    } catch (error) {
      throw new Error("Unable to retrieve client recaptcha visibility")
    }
  }

  async getClientAsset(keyword) {
    try {
      const endpoint = `${PROMOTION_ADMIN_ENDPOINT}client/${CLIENT_ID}/asset/${keyword}`
      const response = await this.get(endpoint)
      return response.data
    } catch (error) {
      throw new Error("Unable to retrieve client asset")
    }
  }

  async getOfferAsset(offerId, keyword) {
    try {
      const endpoint = `${PROMOTION_ADMIN_ENDPOINT}offer/${offerId}/asset/${keyword}`
      const response = await this.get(endpoint)
      return response.data
    } catch (error) {
      throw new Error("Unable to retrieve offer asset")
    }
  }

  async getOfferThanksByKeyword(offerId) {
    try {
      const endpoint = `${PROMOTION_ADMIN_ENDPOINT}${offerId}/offerthanks/`
      const response = await this.get(endpoint)
      return response.data
    } catch (error) {
      throw new Error("Unable to retrieve offer thanks")
    }
  }

  async isValidBangoSubmission(offerId, iSubmitId){
    try {
      const endpoint = `${PROMOTION_ADMIN_ENDPOINT}offer/${offerId}/${iSubmitId}`
      const response = await this.get(endpoint)
      return response.data
    } catch (error) {
      throw new Error("Unable to retrieve the request asset")
    }
  }
}

export default new OfferApi()